export default {
 // Produto
  produtoIdErrors () {
    const errors = []
    if (!this.$v.itemEntityProducts.productId.$dirty) return errors
    !this.$v.itemEntityProducts.productId.required && errors.push('Produto é obrigatório.')
    return errors
  },

  comissionErrors () {
    const errors = []
    if (!this.$v.itemEntityProducts.comission.$dirty) return errors
    !this.$v.itemEntityProducts.comission.required && errors.push('Comissão é obrigatório.')
    return errors
  },

  limitErrors () {
    const errors = []
    if (!this.$v.itemEntityProducts.debtLimit.$dirty) return errors
    !this.$v.itemEntityProducts.debtLimit.required && errors.push('Limit é obrigatório.')
    return errors
  },
}